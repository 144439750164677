import React from 'react';
// import ResponsiveContainer from 'recharts/lib/component/ResponsiveContainer';
// import LineChart from 'recharts/lib/chart/LineChart';
// import Line from 'recharts/lib/cartesian/Line';
// import XAxis from 'recharts/lib/cartesian/XAxis';
// import YAxis from 'recharts/lib/cartesian/YAxis';
// import CartesianGrid from 'recharts/lib/cartesian/CartesianGrid';
// import Tooltip from 'recharts/lib/component/Tooltip';
// import Legend from 'recharts/lib/component/Legend';

// const data = [
//   { name: 'Mon', Visits: 2200, Orders: 3400 },
//   { name: 'Tue', Visits: 1280, Orders: 2398 },
//   { name: 'Wed', Visits: 5000, Orders: 4300 },
//   { name: 'Thu', Visits: 4780, Orders: 2908 },
//   { name: 'Fri', Visits: 5890, Orders: 4800 },
//   { name: 'Sat', Visits: 4390, Orders: 3800 },
//   { name: 'Sun', Visits: 4490, Orders: 4300 },
// ];

function SimpleLineChart() {
  return (
    <div>Hi</div>
  );
}

export default SimpleLineChart;
